<!-- 应用中心 -->
<template>
  <div class="app-container">
    <div class="app-wrap">
      <!-- 板块S -->
      <div class="app-item" v-for="(item) in appLists" :key="item.id" @click="goApplicationPage(item)">
        <!-- 左侧icon -->
        <div class="icon">
          <!-- <img src="../../assets/emailIcon.png" alt="" v-if="index % 2 == 0" />
          <img src="../../assets/emailIcon2.png" alt="" v-if="index % 2 == 1" /> -->
          <img :src="item.icon" alt="">
        </div>
        <!-- 右侧主内容 -->
        <div class="content">
          <!-- 应用标题 -->
          <div class="title">{{ item.name }}{{ item.id }}</div>
          <!-- 应用描述 -->
          <div class="desc">
            {{ item.description }}
          </div>
          <!-- 创建者 -->
          <div class="role">
            <div class="role-admin">
              <img src="../../assets/adminIcon.png" alt="" />
              <p>管理员：{{ item.base.creatorName }}</p>
            </div>
            <div class="role-group">
              <img src="../../assets/groupIcon.png" alt="" />
              <p v-if="item.name==='crm'">IT技术部</p>
              <p v-else-if="item.name==='WiKi'">信息管理部</p>
              <p v-else>其他部门</p>


            </div>
          </div>
        </div>
      </div>
      <!-- 板块E -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onBeforeMount, onMounted, computed } from "vue";
import { appSearch } from "@/utils/api";
import router from '@/router'
export default {
  name: "Application",
  setup() {
    const state = reactive({
      tempSource: [
        {
          appkey: "",
          base: {
            created: "2020-01-01 10:00:00",
            creatorName: "张无忌",
            creatorNo: 12345667,
            updated: "2020-01-01 10:00:00",
            updaterName: "更新人姓名",
            updaterNo: 12345667,
          },
          description:
            "这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字这是应用描述文字",
          icon: "http://a.com/icon?id=xxxxxxx",
          level: 1,
          name: "用户中心",
          page: "http://a.com/ticket/",
          secret: "qweidi",
          status: 1,
        },
      ],
      // 测试数据
      appList: computed(() => {
        return new Array(20).fill("").map((v, i) => {
          v = state.tempSource[0];
          return {
            ...v,
            id: i + 1,
          };
        });
      }),
      // 正式数据
      appLists: [],
    });
    const refData = toRefs(state);
    // 拉取我的应用列表
    const initAppList = async () => {
      try {
        const result = await appSearch();
        const { data, success } = result;
        if (success && data.length > 0) {
          console.log("my app list success", data);
          state.appLists = data;
        }
      } catch (error) {
        console.log("my app list error:", error);
      }
    };
    const goApplicationPage = (item) =>{
      console.log(JSON.stringify(item))
      // const { href } = router.resolve({
      //   //path是要跳转到的页面路径
      //   path: item.page
      // });
      //结合open打开新的页面
      window.open(item.page, "_blank");
    }
    onBeforeMount(() => {
      initAppList();
    });
    onMounted(() => {});
    return {
      ...refData,
      goApplicationPage
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/mixin.scss";
.app-container {
  width: 100%;
  // height: auto;
  box-sizing: border-box;
  // padding: 53px 0;
  height: 100vh;
  overflow: scroll;
  .app-wrap {
    width: 1200px;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 0 60px;
    @media only screen and (min-width: 960px) and (max-width: 1199px) {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    @media only screen and (min-width: 760px) and (max-width: 959px) {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 50px;
    }
    @media only screen and (min-width: 550px) and (max-width: 759px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    // 应用板块
    .app-item {
      display: flex;
      width: 578px;
      height: 185px;
      background: #fff;
      border-radius: 6px;
      flex-direction: row;
      justify-content: flex-start;
      box-sizing: border-box;
      padding: 20px;
      margin-bottom: 31px;
      transition: box-shadow 0.5s;
      transition: transform 0.3s ease-in-out;
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        padding: 30px 20px 20px 20px;
        width: 48%;
        height: 205px;
      }
      @media only screen and (min-width: 960px) and (max-width: 1199px) {
        width: 48%;
        height: 215px;
        padding: 30px 20px 20px 20px;
      }
      // @media only screen and (min-width: 640px) and (max-width: 959px) {
      //   padding: 40px 20px 20px 20px;
      //   width: 48%;
      //   height: 245px;
      // }
      @media only screen and (min-width: 760px) and (max-width: 959px) {
        padding: 30px 20px 20px 20px;
        width: 48%;
        height: 245px;
      }
      @media only screen and (min-width: 550px) and (max-width: 759px) {
        padding: 70px 20px 20px 20px;
        width: 80%;
        height: 355px;
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 30px 0px rgba(214, 222, 225, 0.68);
        transform: translateY(-3px);
        .content .title {
          color: #3d8fff;
          transition: color 0.3s;
        }
      }
      &:nth-child(odd) {
        margin-right: 21px;
        @media only screen and (min-width: 550px) and (max-width: 759px) {
          margin-right: 0px;
        }
      }
      &:nth-child(even) {
        margin-left: 21px;
        @media only screen and (min-width: 550px) and (max-width: 759px) {
          margin-left: 0px;
        }
      }
      .icon {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        margin-right: 18px;
        img {
          display: inline-block;
          height: 100%;
        }
      }
      .content {
        @include flex(column, flex-start, flex-start);
        flex: 1;
        width: 90%;
        .title {
          width: 100%;
          height: 20px;
          font-size: 16px;
          font-family: "SourceRegular";
          font-weight: 400;
          color: #333333;
          line-height: 23px;
        }
        .desc {
          width: 100%;
          height: 80px;
          font-size: 14px;
          font-family: "SourceNormal";
          font-weight: 400;
          color: #8f8f8f;
          line-height: 30px;
          margin: 16px 0 31px 0;
          white-space: wrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          text-overflow: -o-ellipsis-lastline;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          @media only screen and (min-width: 1200px) and (max-width: 1400px) {
            // height: 54px;
            line-height: 30px;
            margin: 16px 0 31px 0;
          }
          @media only screen and (min-width: 960px) and (max-width: 1199px) {
            // height: 80px;
            line-height: 40px;
            margin: 16px 0 28px 0;
          }
          @media only screen and (min-width: 760px) and (max-width: 959px) {
            // height: 80px;
            line-height: 40px;
            margin: 24px 0 28px 0;
          }
          @media only screen and (min-width: 550px) and (max-width: 759px) {
            // height: 120px;
            line-height: 50px;
            margin: 44px 0 28px 0;
          }
        }
        .role {
          display: flex;
          &-admin {
            @include flex(row, flex-start, center);
            margin-right: 52px;
            img {
              margin-right: 10px;
              width: 15px;
              height: 15px;
            }
            p {
              font-size: 14px;
              font-family: "SourceLight";
              font-weight: 300;
              color: #989ea5;
              line-height: 23px;
            }
          }
          &-group {
            @include flex(row, flex-start, center);
            img {
              margin-right: 10px;
              width: 15px;
              height: 15px;
            }
            p {
              font-size: 14px;
              font-family: "SourceLight";
              font-weight: 300;
              color: #989ea5;
              line-height: 23px;
            }
          }
        }
      }
    }
  }
}
</style>
